import "./App.css";
import { Route, Routes } from "react-router-dom";
import Path from "./routes/Path";
import * as View from "./routes/ElementsPath";
import Layout from "./components/layout/Layout";
import { useEffect } from "react";
import Aos from "aos";

import ScrollToTop from "./components/ScrollToTop";
import NestedRoutOutlet from "./components/layout/NestedRoutOutlet";
import StopContextMenu from "./components/StopContextMenu";
import NotFoundPage from "./pages/NotFoundPage";

function App() {

  useEffect(() => {
    Aos.init();
  }, [])
  return (

    <>
    <Layout>
    <StopContextMenu/>
      <ScrollToTop />
      <Routes>
        <Route path={Path.HOME} element={<View.HOME_PATH />} />
        <Route path={Path.ABOUT} element={<View.ABOUT_PATH />} />

        <Route path={Path.COURSES} element={<NestedRoutOutlet />}>
          <Route index element={<View.COURSES_PATH />} />
          <Route path={Path.COURSE_DSA} element={<View.COURSE_DSA />} />
          <Route path={Path.COURSE_JAVA} element={<View.COURSE_JAVA />} />
          <Route path={Path.COURSE_JAVA_FULLSTACK} element={<View.COURSE_JAVA_FULLSTACK />} />
          <Route path={Path.COURSE_PYTHON} element={<View.COURSE_PYTHON />} />
          <Route path={Path.COURSE_PYTHON_FULLSTACK} element={<View.COURSE_PYTHON_FULLSTACK />} />
          <Route path={Path.COURSE_FRONTEND} element={<View.COURSE_FRONEND />} />
          <Route path={Path.COURSE_BACKEND} element={<View.COURSE_BACKEND />} />
          <Route path={Path.COURSE_MERN} element={<View.COURSE_MERN />} />
          <Route path={Path.COURSE_REACT} element={<View.COURSE_REACT />} />
          <Route path={Path.COURSE_DIGITALMARKETING} element={<View.COURSE_DIGITALMARKETING />} />
          <Route path={Path.COURSE_DATA_ANALITICS} element={<View.COURSE_DATAANALITICS />} />
          <Route path={Path.COURSE_DATA_SCIENCE} element={<View.COURSE_DATA_SCIENCE />} />
          <Route path={Path.COURSE_DSA_MASTER} element={<View.COURSE_MASTER_DSA />} />
          <Route path={Path.COURSE_DSA_ADD_FULLSTACK} element={<View.COURSE_DSA_ADDON_FULLSTACK />} />
        </Route>

        <Route path={Path.PLACEMENT} element={<View.PLACEMENTS_PATH />} />
        <Route path={Path.WORKSHOP} element={<View.WORKSHOP_PATH />} />
        <Route path={Path.CONTACT} element={<View.CONTACT_PATH />} />
        <Route path={Path.CAREER} element={<View.CAREER_PATH />} />
        <Route path={Path.INTERNSHIP} element={<View.INTERNSHIP />} />
        <Route path={Path.JOBS} element={<View.JOBS_PATH />} />
        <Route path={Path.TERMS_CONDITIONS} element={<View.TERMS_CONDITIONS_PATH />} />
        <Route path={Path.PRIVACY_POLICY} element={<View.PRIVACY_POLICY_PATH />} />
        <Route path={Path.LIFE_AT_MINDCODERS} element={<View.LIFE_AT_MINDCODERS />} />
        <Route path={Path.SERVICES} element={<View.SERVICES />} />
        <Route path={Path.COLLEGE_TRAINING} element={<View.COLLEGE_TRAINING />} />
        <Route path={Path.CORPORATE_TRAINING} element={<View.CORPORATE_TRAINING />} />

        {/* Blog Routes */}
        <Route path={Path.BLOG} element={<NestedRoutOutlet />}>
          <Route index element={<View.BLOGS_PATH />} />
          <Route path={Path.BLOG_DETAILED} element={<View.BLOG_DETAILED_PATH />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Layout>
    </>  
  );
}

export default App;
