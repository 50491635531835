import React from "react";
import { baseUrl } from "../../helpers/baseUrl";

const CollegeTraining = () => {
  let imgPath = baseUrl();
  return (
    <>
      <section className="py-3 md:py-5 px-3 md:px-5 container overflow-hidden">
        <div className="container mx-auto">
          {/* Text Section */}
          <div>
            <div className="aos-init aos-animate" data-aos="fade-right">
              <h1
                className="text-color-navy text-xl md:text-3xl text-center font-semibold aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="0"
              >
                Train Future Leaders with Industry-Centric Training
              </h1>
              <p className="mt-3  md:mt-5 text-gray-700">
                MindCoders is proud to fill in gaps between academic knowledge
                and the world of practical skills through our highly detailed
                college training programs. We are here to enhance students'
                expertise in the face of enhanced, competitive tasks in today's
                job market.
              </p>
              <p className="mt-3 md:mt-5 text-gray-700 font-bold">
                <b>Why Choose MindCoders for College Training?</b>
              </p>
              <p className="mt-2 md:mt-3 text-gray-700">
                <ul className="list-disc pl-5">
                  <li>Practical learning through live projects.</li>
                  <li>
                    Tailored curriculum for BTech, BBA, BCom, and other streams.
                  </li>
                  <li>
                    Experienced mentors having years of work experience in
                    technology, marketing, and data science.
                  </li>
                  <li>Guaranteed internships and placement assistance.</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Image Section */}
      <section className="wow animated ptb-40 fadeInUp animated">
          <div className="">
            <ul className="row join-list space-y-5 lg:space-y-0">
              <li className="col-lg-2 col-md-3 col-sm-4">
                <div className="ourgrowthImg" data-aos="flip-left">
                  <img
                    src={`${imgPath}/assets/img/home-join/join1.webp`}
                    alt=""
                  />
                  <span className="s12 mt-3 rs-count">2000+</span>
                </div>
              </li>
              {/* Candidates trained */}
              <li className="col-lg-2 col-md-3 col-sm-4">
                <div className="ourgrowthImg" data-aos="flip-left">
                  <img
                    alt=""
                    src={`${imgPath}/assets/img/home-join/join2.webp`}
                  />
                  <span className="s12 mt-3">100% Placed</span>
                </div>
              </li>
              <li className="col-lg-2 col-md-3 col-sm-4">
                <div className="ourgrowthImg" data-aos="flip-left">
                  <img
                    alt=""
                    src={`${imgPath}/assets/img/home-join/join3.webp`}
                  />
                  <span className="s12 mt-3 rs-count">30+</span>
                </div>
              </li>
              <li className="col-lg-2 col-md-3 col-sm-4">
                <div className="ourgrowthImg" data-aos="flip-left">
                  <img
                    alt=""
                    src={`${imgPath}/assets/img/home-join/join4.webp`}
                  />
                  <span className="s12 mt-3 rs-count">400+</span>
                </div>
              </li>
              <li className="col-lg-2 col-md-3 col-sm-4">
                <div className="ourgrowthImg" data-aos="flip-left">
                  <img
                    alt=""
                    src={`${imgPath}/assets/img/home-join/join5.webp`}
                  />
                  <span className="s12 mt-3 rs-count">500+</span>
                </div>
              </li>
              <li className="col-lg-2 col-md-3 col-sm-4">
                <div className="ourgrowthImg" data-aos="flip-left">
                  <img
                    alt=""
                    src={`${imgPath}/assets/img/home-join/join6.webp`}
                  />
                  <span className="s12 mt-3 rs-count">400+</span>
                </div>
              </li>
            </ul>
          </div>
        </section>

      <section className="py-5 mt-5 bg-gray-100 container overflow-hidden">
        <div className="container mx-auto px-4">
          {/* Heading Section */}
          <div>
            <div className="aos-init aos-animate" data-aos="fade-right">
              <h2
                className="text-center text-color-navy text-xl md:text-2xl font-medium aos-init aos-animate border-b-0"
                data-aos="fade-up"
                data-aos-delay="0"
              >
                <strong>
                  Currently covering 15+ Colleges and 10K Students
                </strong>
              </h2>
            </div>
          </div>

          {/* Content Section */}
          <div className="mt-3">
            <div className="aos-init aos-animate" data-aos="fade-left">
              <p className="font-bold text-gray-700">
                MindCoders continues to shape careers and transform education by
                bringing practical skills directly to campuses.
              </p>
              <p className="font-bold text-gray-700 mt-3">
              Our training modules include:
              </p>
              <ul className="list-disc">
                <li>Digital Marketing (SEO, social media, Google Ads)</li>
                <li>Web Development (MERN Stack)</li>
                <li>Data Analytics</li>
                <li>Java Development</li>
                <li>Personality Development & Interview Preparation</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 container overflow-hidden">
        <div className="container mx-auto">
          <div className="w-full">
            <div className="w-full mx-auto">
              {/* Heading */}
              <h2
                className="text-2xl text-color-navy text-center font-semibold aos-init aos-animate"
                data-aos="fade-right"
                data-aos-delay="0"
              >
                Enroll Your College
              </h2>

              {/* Paragraph */}
              <p
                className="mt-4 text-center text-gray-700 aos-init aos-animate"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                If you are interested in bringing this program to
                <br /> your University, reach out to:{" "}
                <a
                  href="mailto:mindcodersindore@gmail.com"
                  className="text-blue-500 hover:underline"
                >
                  mindcodersindore@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CollegeTraining;
