import { baseUrl } from "../helpers/baseUrl";

export const CarouselImg = [
  {
    id: 1,
    image: `${baseUrl()}/assets/img/carousel-img/1.jpg`,
    alt: "Best Digital Marketing Course In Indore",
  },
  {
    id: 2,
    image: `${baseUrl()}/assets/img/carousel-img/2.jpg`,
    alt: "Data Structure & Algorithms, C++ Training",
  },
  {
    id: 3,
    image: `${baseUrl()}/assets/img/carousel-img/3.jpg`,
    alt: "DSA With C&C++ Classes In Indore",
  },
  {
    id: 4,
    image: `${baseUrl()}/assets/img/carousel-img/4.jpg`,
    alt: "Full, Stack Web development Training In Indore",
  },
  {
    id: 5,
    image: `${baseUrl()}/assets/img/carousel-img/5.jpg`,
    alt: "Data Analytics Course In Indore",
  },
  {
    id: 6,
    image: `${baseUrl()}/assets/img/carousel-img/6.jpg`,
    alt: "Data Science Course In Indore",
  },
  {
    id: 7,
    image: `${baseUrl()}/assets/img/carousel-img/7.jpg`,
    alt: "Python Full Stack Training In Indore ",
  },
];
