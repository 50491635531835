
export default function PricingSection({ courseFee }) {
  return (
    <div className="max-w-4xl mx-auto my-8 px-4">
      <div className="flex flex-col md:flex-row border border-gray-200 rounded-md overflow-hidden">
        {/* Left side - Dark background with total fee */}
        <div className="bg-[#172554] text-white p-3 md:w-2/5 flex flex-col justify-center items-center">
          <div className="bg-white text-black p-6 rounded-md w-full ml-0 md:ml-10 max-w-xs">
            <h3 className="text-center font-bold text-lg mb-4">
              TOTAL COURSE FEE
            </h3>
            <p className="text-center text-[#172554!important] text-4xl font-bold">
              ₹ {courseFee.totalFee}
            </p>
            <p className="text-center text-[#ff0000!important] text-xl line-through font-bold">
              ₹ {courseFee.oldFee}
            </p>
          </div>
        </div>

        {/* Right side - White background with EMI details */}
        <div className="bg-white p-8 md:w-3/5 flex flex-col justify-center">
          <h3 className="text-green-600 text-2xl font-bold mb-2">
            Installments
          </h3>
          {/* <p className="text-xl mb-2">starting from 2499/month</p> */}

          <div className="mt-4 space-y-3">
            <div className="flex justify-between items-center border-b border-gray-200 pb-2">
              <span className="font-medium">Registration Fee:</span>
              <span className="font-bold">₹ 1,000</span>
            </div>
            {courseFee.emi.map((emi, index) => (
              <div
                key={index}
                className="flex justify-between items-center border-b border-gray-200 pb-2"
              >
                <span className="font-medium">{emi.installment}:</span>
                <span className="font-bold">₹ {emi.amount}</span>
              </div>
            ))}
            <p className="text-sm text-gray-600 italic">
              *Registration fee of ₹1,000 is included in the total fee
            </p>
          </div>

          <p className="text-xl font-bold mt-4">Hurry! Batch Starting Soon</p>
        </div>
      </div>
    </div>
  );
}
