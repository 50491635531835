import React from "react";

const PrivacyAndPolicy = () => {
  return (
    <section className="feature-section-two pt-120">
      <div className="container ">
        <div className="row overflow-x-hidden align-items-center justify-content-lg-between justify-content-center gap-.1">
          <div className="col-lg-12 col-md-12 col-xl-12 col-sm-12">
            <div className="feature-content-wrap">
              <div className="section-heading ">
                <h2
                  className="pb-2 h2"
                  data-aos="fade-right"
                  data-aos-anchor="#example-anchor"
                  data-aos-offset={500}
                  data-aos-duration={1000}
                >
                  Privacy <span className="text-info">&amp;</span> Policy{" "}
                </h2>
                <p
                  data-aos="fade-left"
                  data-aos-anchor="#example-anchor"
                  data-aos-offset={500}
                  data-aos-duration={1000}
                >
                  This Privacy Policy describes how Mindcoders collects, uses,
                  and discloses information that we obtain from visitors to our
                  website <a className="text-info" href="https://www.mindcoders.in/">mindcoders.in</a>
                </p>
                <h4 className="h4 mt-3" data-aos="fade-right">
                  Information We Collect
                </h4>
                <ul data-aos="fade-left">
                  <li>
                    <strong>Personal Information: </strong>We may collect
                    personal information such as your name, email address, and
                    other contact details when you voluntarily provide them to
                    us through forms on the Website.
                  </li>
                  <li>
                    <strong>Usage Data: </strong>We may automatically collect
                    certain information about your device and how you interact
                    with our Website. This may include your IP address, browser
                    type, operating system, referring URLs, and other usage
                    data.
                  </li>
                </ul>
                <h4 className="h4 mt-3" data-aos="fade-right">
                  Use of Information
                </h4>
                <p data-aos="fade-left">
                  We may use the information we collect for the following
                  purposes:{" "}
                </p>
                <ul data-aos="fade-left">
                  <li>To provide and maintain our Website;</li>
                  <li>
                    To communicate with you and respond to your inquiries;
                  </li>
                  <li>
                    To analyze how users interact with our Website and improve
                    its functionality and user experience;
                  </li>
                  <li>To detect, prevent, and address technical issues; and</li>
                  <li>To comply with legal obligations.</li>
                </ul>
                <h4 className="h4 mt-3" data-aos="fade-right">
                  Cookies Policy
                </h4>
                <p data-aos="fade-left">
                  To enhance your online experience and track website
                  performance, our website uses cookies. It’s a small text file
                  which gets placed in your computer hard drive and can be
                  retrieved later. Cookies do not tell us who you are.
                </p>
                <h4 className="h4 mt-3" data-aos="fade-right">
                  Data Sharing
                </h4>
                <p data-aos="fade-left">
                  We may share your information with third-party service
                  providers who assist us in operating our Website, conducting
                  our business, or providing services to you. These third
                  parties are obligated to maintain the confidentiality and
                  security of your information.
                </p>
                <p data-aos="fade-left">
                  We may also disclose your information in response to a lawful
                  request by public authorities, including to meet national
                  security or law enforcement requirements.
                </p>
                <h4 className="h4 mt-3" data-aos="fade-right">
                  Data Retention
                </h4>
                <p data-aos="fade-left">
                  We will retain your personal information only for as long as
                  necessary to fulfill the purposes outlined in this Privacy
                  Policy, unless a longer retention period is required or
                  permitted by law.
                </p>
                <h4 className="h4 mt-3" data-aos="fade-right">
                  Your Rights
                </h4>
                <p data-aos="fade-left">
                  You may have certain rights regarding your personal
                  information, including the right to access, correct, or delete
                  your information. Please contact us using the information
                  provided below if you wish to exercise these rights.
                </p>
                <h3 className="h3 mt-4" data-aos="fade-right">
                  {" "}
                  <span className="text-info">Refund</span> and{" "}
                  <span className="text-info">Cancellation</span> Policy:
                </h3>
                <h4 className="h4 mt-2" data-aos="fade-right">
                  Refund Policy
                </h4>
                <p data-aos="fade-left">
                  We want you to be satisfied with your purchase. If you are not
                  completely satisfied with your purchase for any reason, you
                  may request a refund within 3 days of the purchase date. To
                  request a refund, please contact us at contact@mindcoders.in
                  with your order details. Refunds will be credited to the
                  original method of payment and may take 3 days to process.
                </p>
                <p data-aos="fade-left">
                  Please note that certain products or services may not be
                  eligible for a refund. This includes but is not limited to
                  downloadable products or services that have already been
                  provided.
                </p>
                <h4 className="h4 mt-3" data-aos="fade-right">
                  Cancellation Policy
                </h4>
                <p data-aos="fade-left">
                  You may cancel your subscription or service at any time by
                  contacting us at contact@mindcoders.in. If you cancel within 3
                  days of the start of your subscription or service, you may be
                  eligible for a full or partial refund, depending on the terms
                  of your subscription or service agreement.
                </p>
                <p data-aos="fade-left">
                  Please note that some subscriptions or services may have a
                  minimum commitment period, and early cancellation may result
                  in fees or penalties.
                </p>
                <h4 className="h4 mt-3" data-aos="fade-right">
                  Use of Information
                </h4>
                <p data-aos="fade-left">
                  If you for some reason believe that your personal information
                  should not feature in our website and social media channels
                  and would like to delete your account, you can write to us at &nbsp;
                  <strong>contact@mindcoders.in</strong>
                </p>
                <h4 className="h4 mt-3" data-aos="fade-right">
                  Contact Us
                </h4>
                <p data-aos="fade-left">
                  If you have any questions or concerns about our Privacy
                  Policy, please contact us at &nbsp;
                  <strong>contact@mindcoders.in</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacyAndPolicy;
