import React from "react";
import { EnquiryApi } from "../helpers/enquiryAPI/EnquiryApi";
import { useForm } from "react-hook-form";

const ContactForm = () => {
  const { register, handleSubmit, reset, formState: { isSubmitting } } = useForm();

  const onSubmit = async (data) => {
    try {
     await EnquiryApi(data);
      reset();
    } catch (error) {
      console.error("Failed to submit enquiry:", error);
    }
  };

  return (
    <>
      <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <h3 className="sm:text-2xl md:text-lg lg:text-3xl text-color-navy font-bold text-center">
          Feel free to contact us!
        </h3>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="relative">
            <div className="absolute inset-y-0 left-4 flex items-center z-10">
              <span className="bg-transparent pr-3 border-r border-gray-700">
                <i className="fa-solid fa-user text-[#1f2471] text-lg"></i>
              </span>
            </div>
            <input
              type="text"
              className="form-input pl-16 pr-4 py-3 rounded-full shadow-md w-full focus:outline-none focus:ring-2 "
              id="fullname"
              placeholder="Fullname"
              name="fullname"
              required
              {...register("fullname")}
            />
          </div>

          <div className="relative">
            <div className="absolute inset-y-0 left-4 flex items-center z-10">
              <span className="bg-transparent pr-3 border-r border-gray-700">
                <i className="fa-solid fa-phone text-[#1f2471] text-lg"></i>
              </span>
            </div>
            <input
              type="number"
              className="form-input pl-16 pr-4 py-3 rounded-full shadow-md w-full focus:outline-none focus:ring-2"
              maxLength="10"
              id="number"
              placeholder="Phone No."
              name="number"
              required
              {...register("number")}
            />
          </div>

          <div className="relative">
            <div className="absolute inset-y-0 left-4 flex items-center z-10">
              <span className="bg-transparent pr-3 border-r border-gray-700">
                <i className="fa-solid fa-envelope text-[#1f2471] text-lg"></i>
              </span>
            </div>
            <input
              type="email"
              className="form-input pl-16 pr-4 py-3 rounded-full shadow-md w-full focus:outline-none focus:ring-2 "
              id="email"
              placeholder="Email Address"
              name="email"
              required
              {...register("email")}
            />
          </div>

          <div className="relative">
            <div className="absolute inset-y-0 left-4 flex items-center z-10">
              <span className="bg-transparent pr-3 border-r border-gray-700">
                <i className="fa-solid fa-graduation-cap text-[#1f2471] text-lg"></i>
              </span>
            </div>
            <input
              type="text"
              className="form-input pl-16 pr-4 py-3 rounded-full shadow-md w-full focus:outline-none focus:ring-2 "
              id="college"
              placeholder="College"
              name="college"
              required
              {...register("college")}
            />
          </div>

          <div className="relative">
            <select
              required
              {...register("course")}
              name="course"
              id="course"
              className="form-select pr-4 py-3 rounded-full shadow-md w-full"
            >
              <option value="">-Select Course-</option>
              <option value="Front-End Course">Front-End Course</option>
              <option value="Back-End Course">Back-End Course</option>
              <option value="React JS Course">React JS Course</option>
              <option value="MERN Stack Course">MERN Stack Course</option>
              <option value="Data Analytics Course">
                Data Analytics Course
              </option>
              <option value="Digital Marketing Course">
                Digital Marketing Course
              </option>
              <option value="Data Science">Data Science</option>
              <option value="DSA">DSA</option>
              <option value="Java Programming">Java Programming</option>
              <option value="Java Fullstack">Java Fullstack</option>
              <option value="Python Programming">Python Programming</option>
              <option value="Python Fullstack">Python Fullstack</option>
            </select>
          </div>

          <div className="relative">
            <select
              required
              {...register("city")}
              name="city"
              id="city"
              className="form-select pr-4 py-3 rounded-full shadow-md w-full"
            >
              <option>-Select City-</option>
              <option value="Indore">Indore</option>
              <option value="Ujjain">Ujjain</option>
              <option value="Bhopal">Bhopal</option>
              <option value="Dewas">Dewas</option>
              <option value="Khargone">Khargone</option>
              <option value="Barwaha">Barwaha</option>
              <option value="Dhar">Dhar</option>
              <option value="Dhamnod">Dhamnod</option>
              <option value="Maheshwar">Maheshwar</option>
              <option value="Sanwer">Sanwer</option>
              <option value="Depalpur">Depalpur</option>
              <option value="Hatod">Hatod</option>
              <option value="Betma">Betma</option>
              <option value="Manpur">Manpur</option>
              <option value="Sanawad">Sanawad</option>
              <option value="Vadodara">Vadodara</option>
              <option value="Jabalpur">Jabalpur</option>
              <option value="Sagar">Sagar</option>
              <option value="Balaghat">Balaghat</option>
              <option value="Delhi">Delhi</option>
              <option value="Mumbai">Mumbai</option>
              <option value="Pune">Pune</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>

        <div className="relative">
          <div className="absolute top-4 left-4 flex items-center z-10">
            <span className="bg-transparent pr-3 border-r border-gray-700">
              <i className="fa-solid fa-message text-[#1f2471] text-lg"></i>
            </span>
          </div>
          <textarea
            className="form-textarea pl-16 pr-4 py-3 rounded-full shadow-md w-full focus:outline-none focus:ring-2 "
            id="message"
            name="message"
            placeholder="Your Message....."
            {...register("message")}
          ></textarea>
        </div>

        <div className="flex justify-center">
        <button
            type="submit"
            className="flex items-center justify-center h-12 w-fit bg-[#172554] rounded-full text-white font-bold transition-all duration-200 ease-linear hover:shadow-lg hover:transform hover:-translate-y-1 px-5"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <span className="text-white">Submitting...</span>
                <i className="fa-solid fa-spinner ml-2 animate-spin"></i>
              </>
            ) : (
              <>
                <span className="text-white">Submit</span>
                <i className="fa-solid fa-arrow-right ml-2 text-lg"></i>
              </>
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default ContactForm;
