import { ScrollProgress } from "./magicui/ScrollProgress.tsx";

export function UpperScrollBar() {
  return (
    <div className="z-10 rounded-lg">
      <ScrollProgress className="top-[0px]" />
      {/* <h2 className="pb-4 font-bold">
        Note: The scroll progress is shown below the navbar of the page.
      </h2> */}
    </div>
  );
}
